import React from 'react'
import styled from 'styled-components'

export default function Personal() {
    window.scrollTo(0,0)
    return (
        <PersonalWrapper>
            <div className="consContainer">
                <i className="fas fa-route" data-aos="zoom-in"></i>
                <h5 className="consTitle" data-aos="zoom-in-right">
                    Личностно развитие
                </h5>
                <div className="consText container">
                    <p className="consP">
                    За да поддържаме баланса и качеството си на живот, е необходимо да отделяме периодично внимание на мислите, чувствата и общото си състояние. Всичко е взаимосвързано и динамично, т.е. както постоянно се променят външните, вторични обстоятелства, така се променят и потребностите ни, усещанията и вътрешния ни свят от чувства и мисли. 
                    </p>
                    <p className="consP">
                    Ключът за поддържане на хармония и цялостност е в култивирането на осъзнатост по отношение на състоянието ни и ежедневна грижа за тялото, ума и дишането ни. Едва когато възпитаме необходимото внимание и навик в тази посока, ще изпитваме и радостта от съществуването си. </p>
                    <p className="consP">
                    Дори и без да са се проявили сериозни симптоми, стреса и скоростта на живот достатъчно напрягат ежедневието ни. Всичко това се отразява на телесно, ментално и емоционално ниво. Периодичните занимания, които предлагам, с всички методи, с които работя (Холотропно дишане, Хармонично дишане, СоулКолаж и Медитация), имат за цел да помагат регулярно да разтоварваме натрупаните си напрежения и да придобием умения и навици да обръщаме внимание на тялото, ума и дишането си. </p>
                    <p className="consP">
                    В групите по медитация и хармонично дишане ще имате възможност да научите много упражнения за трениране на ума и развиване на пълно, плавно и естествено дишане, в комбинация с упражнения за загряване на тялото. Всички упражнения са напълно приложими и в домашни условия.  </p>
                    <p className="consP">
                    Възползвайте се от тези възможности и знание добре, за да бъдете здрави, хармонични и цялостни в живота си!</p>
                </div>
            </div>
        </PersonalWrapper>
    )
}

const PersonalWrapper = styled.div`
    padding: 2rem;
    margin-top: 5rem;
    .fas {
        font-size: 5rem;
        color: var(--mainGold);
    }
    .consTitle {
        margin-top: 1rem;
        margin-bottom: 2rem;
        font-size: 3rem;
        color: var(--mainBlue);
    }
    .consText {
        text-align: left;
        color: var(--mainDark);
    }
    .consP {
        margin-bottom: 2rem;
    }
`