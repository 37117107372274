import React from 'react'
import styled from 'styled-components'

export default function Therapy() {
    window.scrollTo(0,0)
    return (
        <TherapyWrapper>
            <div className="consContainer">
                <i className="fas fa-brain" data-aos="zoom-in"></i>
                <h3 className="consTitle" data-aos="zoom-in-right">
                    Психотерапия
                </h3>
                <div className="consText container">
                    <p className="consP">
                        Психотерапията предлага възможност да изследвате своите мисли, емоции и преживявания в безопасна и подкрепяща среда, в 
                        която може да се срещнете и отработите осъзнаването на трудни и болезнени ситуации. Тази дълбинна работа може да бъде 
                        трансформативна – като ви даде шанс да разберете смисъла на своите симптоми; да изследвате своите негативни вярвания и модели; 
                        както и да помислите и се насочите към личностното ви израстване и благополучие. Психотерапията може да доведе до инсайти и 
                        дълбоки прозрения, в резултат на които животът ви да стане по-смислен и удовлетворителен.
                    </p>
                    <p className="consP">
                    Продължителността на терапевтичната работа е различна. Обикновено терапевтичната работа изисква посвещаване на време и 
                    постоянство, за да се осъзнае огромния потенциал за трайна промяна и да се сформират нови нагласи и модели.</p>
                    <h5 className="therapyTitle">
                        Трансперсонална психология и психотерапия
                    </h5>
                    <p className="consP">Трансперсоналната психология обединява в себе си прозренията на личностните психологии от Запада с духовните психологии от Изтока. Това е клон от психологията, който разпознава и приема духовността като важно измерение от човешката психика и от универсалната система на нещата. Изучава и почита целия спектър от човешки преживявания, включително разнообразни нива и области от психиката, проявяващи се в необичайни състояния на съзнанието. Тук се отнасят например преживявания и наблюдения от медитацията и други форми на систематични духовни практики, спонтанни мистични екстази, психо-духовни кризи, психеделична терапия, хипноза, експериментална психотерапия и около-смъртни ситуации. </p>
                    <p className="consP">Основен принцип в трансперсоналната психология е разбирането, че в човешката психика има вроден подтик към баланс и цялостност и тя има тенденция да се самоизцелява. Идеята за цялостна и балансирана личност е свързана с това в процеса на себеизследване и себепознание да се обединим с всички свои аспекти, независимо от това дали ги оценяваме като положителни или отрицателни. Интегрирането на т.нар. несъзнавана или сенчеста страна на личността е важна част от този процес. Цялостна и балансирана личност не означава съвършена личност, а по-скоро пълнокръвен, жив и хармоничен организъм.</p>
                    <h5 className="therapyTitle">
                        Когнитивно-поведенческа терапия
                    </h5>
                    <p className="consP">Когнитивно-поведенческата терапия се води проблемориентирана терапия, защото се работи с когнитивните модели на клиентите, които са резултат от начина, по който възприемаме отделните житейски събития или ситуации и важността, която им придаваме. Това е краткосрочна терапия, чиято цел е да се промени именно значението и важността, която придаваме на случващото се. Работи се с дисфункционалното мислене.</p>
                    <p className="consP">Нашите мисли не са реалността, изследването и проверката им е важна част от терапията. Да се научим да не се отъждествяваме с тях е ефект, който може да доведе до промени в качеството ни на живот.</p>
                    <h5 className="therapyTitle">
                        Основана на майндфулнес психотерапия
                    </h5>
                    <p className="consP">Този вид терапия се основана на инсайти, свързани с майндфулнес. Всъщност майндфулнес означава да се отнасяме с осъзнато, безоценъчно внимание към всичко, което възниква във вътрешния и външния ни свят. Това значи да сме напълно наясно за това, което се случва във всеки момент, без да прилагаме каквото и да е усилие, за да променим този опит. Това предполага да прилагаме “свежия ум на начинаещия” към всеки момент и да развием отношение на приемане.</p>
                    <p className="consP">Тази ориентация е много полезна в терапията, тъй като обръща внимание на осъзнаването на това, което се случва във всеки момент, без всякакво усилие да се променя това преживяване. Развиването на способността просто да „бъдеш“ с нещата такива, каквито са, насърчава отношение на отворено, релаксирано приемане. Подходът майндфулнес намалява стреса и отпуска привичните напрежения чрез възпитаване на умение за постигане на спокойствие по средата на вътрешен или външен смут и хаос. Помага да се ускори себе-отразяването, осъзнаването и емоционалното самообладание, както и да се развие по-голямо състрадание към себе си и другите.</p>
                    <h5 className="therapyTitle">
                        Невербални терапии
                    </h5>
                    <p className="consP">Творчеството се счита за основополагащ инструмент за самоизцеление чрез обръщане на внимание на символичното общуване от несъзнаваното. Невербално ориентираната работа може да изследва индивидуалната психика с помощта на образи, символи, въображение, асоциации.</p>
                    <p className="consP">
                        Експресивните терапии предлагат  път за изследване на невербални символи и метафори, които се проявяват в творческия процес. Много често мисли и емоции, които са трудни да бъдат облечени в думи, намират своя уникален израз чрез креативна, невербална работа, при което надежди, спомени, страхове и конфликти могат да бъдат изразени във форма, която няма предходен език.</p>
                </div>
            </div>
        </TherapyWrapper>
    )
}

const TherapyWrapper = styled.div`
    padding: 2rem;
    margin-top: 5rem;
    .fas {
        font-size: 5rem;
        color: var(--mainGold);
    }
    .consTitle {
        margin-top: 1rem;
        margin-bottom: 2rem;
        font-size: 3rem;
        color: var(--mainBlue);
    }
    .consText {
        text-align: left;
        color: var(--mainDark);
    }
    .consP {
        margin-bottom: 2rem;
    }
    .listItem {
        margin-left: 5rem;
    }
    h5 {
        color: var(--mainGold);
    }
`
